import React from "react";
import "../css/Footer.scss";
import "../css/PersonalDiscount.scss";
import { Container, Row, Col } from "./Bootstraper";
import { Link } from "react-router-dom";
import Wrapper from "./Wrapper.js";
//import FormMaterial from "./FormMaterial.js";
import FormatText from "./FormatText.js";
import TCaption from "./TCaption.js";

const FormMaterial = React.lazy(() => import("./FormMaterial.js"));

function PersonalDiscount(props) {
  const bgColor = props.backgroundColor ? props.backgroundColor : "#ffffff";

  let submitButton = "Получить скидку";
  let additionalClass = "";
  submitButton = (
    <button className="buyButton" style={{ marginBottom: "0px" }}>
      {submitButton}
    </button>
  );
  additionalClass = " with-button";
  if (!!props.redSub) {
    additionalClass += " with-red-sub";
  }

  let subText = (
    <div className="personal-discount-sub">
      Получите персональную
      <br />
      скидку
    </div>
  );

  return (
    <Wrapper bid="personal-discount" lazyLoad backgroundColor={bgColor}>
      <div className={`__PersonalDiscount${additionalClass}`}>
        <Container>
          <Row>
            <Col xs="12">
              <TCaption.Wrapper ext center>
                <TCaption.Spacer height="38px" />
                <TCaption.Sub>{subText}</TCaption.Sub>
                <TCaption.Text>
                  Напишите нам о&nbsp;заинтересовавшем вас продукте,
                  и&nbsp;мы&nbsp;свяжемся с&nbsp;вами с&nbsp;персональным
                  предложением
                </TCaption.Text>
              </TCaption.Wrapper>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <React.Suspense fallback={<></>}>
                <FormMaterial
                  formId="form-discount"
                  submitText={submitButton}
                  submitClass="gtm_btn_form_persskidka"
                  submitUrl={`https://dev.casada-russia.ru/api/bitrix/form/personal_discount`}
                  method="POST"
                >
                  {[
                    {
                      type: "text",
                      name: "name",
                      label: "Имя",
                      placeholder: "Ваше имя",
                      required: true,
                    },
                    {
                      type: "phone",
                      name: "phone",
                      label: "Телефон",
                      placeholder: "Ваш телефон",
                      required: true,
                    },
                    {
                      type: "text",
                      name: "city",
                      label: "Город",
                      placeholder: "Ваш город",
                      required: true,
                    },
                    {
                      type: "textarea",
                      rows: 3,
                      name: "msg",
                      label: "Какой товар вас интересует?",
                      placeholder:
                        "На какой товар вы хотели бы получить скидку?",
                      required: true,
                    },
                  ]}
                </FormMaterial>
              </React.Suspense>
              <TCaption.Spacer height="18px" />
              <FormatText type="text-small">
                Оставляя свои данные вы даете согласие на <br />
                <Link to="/agree/" className="link-policy hl-red">
                  обработку персональных данных
                </Link>
              </FormatText>
            </Col>
          </Row>
        </Container>
      </div>
    </Wrapper>
  );
}

export default PersonalDiscount;
